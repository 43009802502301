import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../Environment";
import configuration from "react-global-configuration";

class Welcome extends Component {
  state = {
    user_details: null,

    isLoaded: false
  };
  componentDidMount() {
    console.log("checking");
    api
      .postMethod("profile")
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            this.setState({
              user_details: response.data.data,

              isLoaded: true
            });
            console.log("user", this.state.user_details);
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }
  render() {
    const { isLoaded, user_details } = this.state;
    return (
      <div className="welcome-animated">
        {/* <canvas id="canvas"></canvas> */}
        <div className="welcome">
          <div className="">
            <Link to={"/"}>
              <img
                src={configuration.get("configData.site_logo")}
                className="site-logo welcome-logo"
                alt={configuration.get("configData.site_name")}
              />
            </Link>
            <div className="text-center">
              <img
                src="../../assets/img/welcome.png"
                className="welcome-img"
                alt="umusic-welcome"
              />
            </div>
            <div className="text-center p-3">
              <h1>
                Hello{" "}
                <span className="capz">
                  {isLoaded ? user_details.name : ""}.!
                </span>
              </h1>
            </div>
            <div className="text-center">
              <Link to={"/"} className="btn btn-warning btn-solid">
                continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
