import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Background from "../../assets/img/sidebar.png";

import api from "../../Environment";

import { apiConstants } from "../Constant/Constants";

import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';

var sectionStyle = {
  backgroundImage: `url(${Background})`
};

const $ = window.$;

const REDIRECT_URI = window.location.href;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login_form: {
        email: "",

        password: ""
      },

      redirect: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    const { name, value } = event.target;

    const { login_form } = this.state;

    this.setState({
      login_form: { ...login_form, [name]: value }
    });
  }

  handleSocialLoginFailure(err) {
    console.error(err);
  }

  responseFacebook(fb_response) {
    console.log(fb_response);
    var details = {
      social_unique_id: fb_response.userID,

      first_name: fb_response.firstName,

      last_name: fb_response.lastName,

      name: fb_response.name,

      email: fb_response.email === undefined || fb_response.email === null
      ? fb_response.id + "@facebook.com"
      : fb_response.email,

      picture: fb_response.picture,

      login_by: "facebook",
    };

    api
      .postMethod("register", details)
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            $("#login-close").click();

            $.toast({
              heading: "Success",
              text: "Successfully, completed your Registration process",
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            this.setState({ redirect: true });
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log(err);

        console.log("oops something went wrong");
      });
  }

  responseGoogle(googleData) {
    console.log(googleData);
    var details = {
      social_unique_id: googleData.sub,
      first_name: googleData.firstName,
      last_name: googleData.lastName,
      name: googleData.name,
      email: googleData.email,
      picture: googleData.picture,
      login_by: "google",
    };

    api
      .postMethod("register", details)
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            $("#login-close").click();

            $.toast({
              heading: "Success",
              text: "Successfully, completed your Registration process",
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            this.setState({ redirect: true });
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log(err);

        console.log("oops something went wrong");
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      // form is invalid! so we do nothing

      return;
    }

    // form is valid! We can parse and submit data

    api
      .postMethod("login", this.state.login_form)
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            $("#login-close").click();

            $.toast({
              heading: "Success",
              text: response.data.message,
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            this.setState({ redirect: true });
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  closeLogin() {
    $("#login-close").click();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/profile"} />;
    }

    return (
      <div className="modal fade" id="login">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="row m-0">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0">
                <div style={sectionStyle} className="auth-bg-img" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0 auth-bg-content">
                <div className="modal-header">
                  <h4 className="modal-title">happy to you see you again!</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="login-close"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body auth-form">
                  <form id="login_form" onSubmit={this.handleSubmit}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img
                            src="../../assets/icons/email-gold.png"
                            className="auth-img"
                            alt="Email"
                          />
                        </span>
                      </div>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="email"
                        required
                        minLength="4"
                        maxLength="128"
                        title="Enter Minimum 4 and Maximum 128 characters"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img
                            src="../../assets/icons/lock-gold.png"
                            className="auth-img"
                            alt="Gold"
                          />
                        </span>
                      </div>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="password"
                        required
                        minLength="6"
                        maxLength="128"
                        title="Enter Minimum 6 and Maximum 128 characters"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="text-right">
                      <Link
                        to="#"
                        className="link"
                        data-toggle="modal"
                        data-target="#forgot"
                        id="forgot-link"
                        onClick={this.closeLogin}
                      >
                        forgot password?
                      </Link>
                    </div>
                    <div className="text-center">
                      <button className="btn btn-warning btn-solid">
                        login
                      </button>
                    </div>
                    <div className="text-center">
                      <p className="mt-3 mb-0 capitalize">
                        don't have an account?
                        <Link
                          to="#"
                          className="link"
                          data-toggle="modal"
                          data-target="#register"
                          id="register-link"
                          onClick={this.closeLogin}
                        >
                          &nbsp;register
                        </Link>
                      </p>
                    </div>
                  </form>

                  <div className="login-separator">Or login with</div>
                  <div className="text-center">
                    {apiConstants.FACEBOOK_APP_ID ? (
                      <LoginSocialFacebook
                        appId={apiConstants.FACEBOOK_APP_ID}
                        fieldsProfile={
                          'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        }
                        // onLoginStart={onLoginStart}
                        // onLoginSuccess={this.responseFacebook}
                        // onLoginFailure={this.handleSocialLoginFailure}
                        redirect_uri={REDIRECT_URI}
                        onResolve={({ provider, data }: IResolveParams) => {
                          this.responseFacebook(data);
                        }}
                        onReject={err => {
                          console.log(err);
                        }}
                      >
                        <FacebookLoginButton />
                      </LoginSocialFacebook>
                    ) : (
                      ""
                    )}
                    {apiConstants.GOOGLE_CLIENT_ID ?
                      <LoginSocialGoogle
                        client_id={apiConstants.GOOGLE_CLIENT_ID}
                        // onLoginSuccess={this.responseGoogle}
                        // onLoginFailure={this.handleSocialLoginFailure}
                        redirect_uri={REDIRECT_URI}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        access_type="offline"
                        onResolve={({ provider, data }: IResolveParams) => {
                          this.responseGoogle(data);
                        }}
                        onReject={err => {
                          console.log(err);
                        }}
                      >
                        <GoogleLoginButton />
                      </LoginSocialGoogle>
                      : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
